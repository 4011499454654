import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const SkillAssignmentsData = [
  {
    title: "SQL",
    description:
      "Our SQL assignments will help you sharpen your database management skills and become a SQL expert.",
  },
  {
    title: "Java",
    description:
      "Excel in Java programming, from OOP to application development, with our tailored assignments.",
  },
  {
    title: "JavaScript",
    description:
      "Enhance skills, work with the DOM, and create dynamic web interfaces using our JavaScript assignments.",
  },
  {
    title: "DSA",
    description:
      "Optimize problem-solving skills and learn to design efficient algorithms with our DSA assignments.",
  },
  {
    title: "Python",
    description:
      "Enhance your programming skills through practical problem-solving with our Python assignments.",
  },
  {
    title: "C++",
    description:
      "Excel in C++ with our skill assignments and become a coding expert.",
  },
];

type SphereEngineStandaloneProps = {
  hash: string;
};

const SphereEngineStandalone: React.FC<SphereEngineStandaloneProps> = ({
  hash,
}) => {
  const srcUrl = `https://e517096a.widgets.sphere-engine.com/lp?hash=${hash}`;
  return (
    <iframe
      src={srcUrl}
      width="100%"
      height="755px"
      title={`Sphere Engine Widget ${hash}`}
    ></iframe>
  );
};

const SkillAssignment = () => {
  const { skillTitle } = useParams();
  const navigate = useNavigate();

  const skillAssignment = SkillAssignmentsData.find(
    (assignment) => assignment.title === skillTitle
  );

  const handleGoBack = () => {
    navigate("/skill-assignments");
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    setShowModal(false);
    handleGoBack();
  };

  let widgetHashes: string[] = [];

  switch (skillAssignment?.title) {
    case "SQL":
      widgetHashes = ["JeLQemgPd0", "AYiFV4cCSl"];
      break;
    case "Java":
      widgetHashes = ["Y1aJt3qyVH", "RyCSjXmmT2"];
      break;
    case "JavaScript":
      widgetHashes = ["9AiszNEN57", "RynbM49QDY", "jaTSenutYB"];
      break;
    case "DSA":
      widgetHashes = ["RyCSjXmmT2", "9AiszNEN57"];
      break;
    case "Python":
      widgetHashes = ["YiPCLNT9Xa", "W9LHPNFQTE"];
      break;
    case "C++":
      widgetHashes = ["uJbybVLY2C", "JhKaaEla1Y"];
      break;
    default:
      widgetHashes = [];
      break;
  }
    
  const [selectedHash, setSelectedHash] = useState<string>(widgetHashes[0]);

  const handleQuestionClick = (hash: string) => {
    setSelectedHash(hash);
  };

  if (!skillAssignment) {
    return <div>Skill not found</div>;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#D4CFFF",
          padding: "10px 20px",
        }}
      >
        <button
          onClick={handleGoBack}
          style={{
            background: "transparent",
            border: "none",
            fontSize: "16px",
            color: "#7D6EEB",
            cursor: "pointer",
          }}
        >
          &lt; Skill Assignments
        </button>
        <div className="flex flex-col items-center">
          <div className="font-bold text-4xl py-4">{skillAssignment.title}</div>

          <div className="pb-2">{skillAssignment.description}</div>
        </div>
        <button
          onClick={openModal}
          style={{
            position: "relative",
            display: "inline-block",
            margin: "8px",
            padding: "8px 16px",
            textAlign: "center",
            fontSize: "12px",
            letterSpacing: "1px",
            textDecoration: "none",
            color: "#FF0000",
            background: "transparent",
            cursor: "pointer",
            transition: "ease-out 0.5s",
            border: "2px solid #FF0000",
            borderRadius: "8px",
            boxShadow: "inset 0 0 0 0 #FF0000",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.color = "white";
            e.currentTarget.style.boxShadow = "inset 0 -100px 0 0 #FF0000";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.color = "#FF0000";
            e.currentTarget.style.boxShadow = "inset 0 0 0 0 #FF0000";
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.transform = "scale(0.9)";
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          X Exit
        </button>
        {showModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "2px solid #7D6EEB",
                gap: "30px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                Are you sure you want to exit?
              </p>
              <div style={{ display: "flex", gap: "20px" }}>
                <button
                  onClick={handleExit}
                  style={{
                    background: "#E21616",
                    border: "none",
                    fontSize: "16px",
                    color: "white",
                    padding: "8px 16px",
                    cursor: "pointer",
                    borderRadius: "7px",
                  }}
                >
                  Exit
                </button>
                <button
                  onClick={closeModal}
                  style={{
                    background: "#D9D9D9",
                    border: "none",
                    fontSize: "16px",
                    color: "#000000",
                    padding: "8px 16px",
                    cursor: "pointer",
                    borderRadius: "7px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex pt-4">
        <div className="flex flex-col pt-4 pl-2">
          {widgetHashes.map((hash, index) => (
            <button
              key={hash}
              onClick={() => handleQuestionClick(hash)}
              style={{
                padding: "10px 25px",
                marginBottom: "10px",
                marginRight: "10px",
                borderRadius: "10px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
                border: selectedHash === hash ? "none" : "1px solid #7D6EEB",
                backgroundColor:
                  selectedHash === hash ? "#7D6EEB" : "transparent",
                color: selectedHash === hash ? "#FFFFFF" : "#7D6EEB",
                cursor: "pointer",
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <SphereEngineStandalone key={selectedHash} hash={selectedHash} />
      </div>
    </div>
  );
};

export default SkillAssignment;
